import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Logo from '../../../foundation/assets/images/dashdot_logo_name_black.png';
import GoogleLogo from '../../../foundation/assets/svgs/Google.svg';
import { encryptData } from '../../../foundation/utils/api';
import envConstants from '../../../internals/env/env_constants.json';
import { fetchClientData, login } from '../redux/async_thunks';
import { selectClientIp } from '../redux/selectors';
import { setClientId, setOAuthInfo } from '../redux/slice';

const Login = () => {
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();

  const client_ip: any = useSelector(selectClientIp);

  const history = useHistory();

  const googleOAuthID = envConstants.GOOGLE_OAUTH_ID;

  const errorHandler = (description?: any, message?: any) => {
    notification.error({
      message: description ? description : <strong>Error</strong>,
      description: message
        ? message
        : `Something went wrong, please try again later.`,
    });
    setIsLoading(false);
    sessionStorage.clear();
    history.push('/');
  };

  const responseGoogle = async (response: any) => {
    try {
      setIsLoading(true);
      if (response.error) {
        return setIsLoading(false);
      }

      if (response.profileObj === undefined) {
        return errorHandler('An error occured. Please login again.');
      }

      const userInfo = {
        client_ip: client_ip?.ipv4,
        email_address: response.profileObj?.email,
        google_token: response.tokenObj?.id_token,
      };
      const loginResponse = await dispatch(
        login({
          data: {
            emailAddress: userInfo.email_address,
            googleToken: userInfo.google_token,
            clientIp: client_ip?.ipv4,
          },
        }),
        // @ts-ignore
      ).unwrap();
      if (loginResponse.data) {
        const { data } = loginResponse;
        dispatch(
          setClientId(
            encryptData(
              `${window.navigator.userAgent}/${client_ip.ipv4}/${data.session_id}`,
            ),
          ),
        );
      }

      if (loginResponse.status === 204) {
        errorHandler(
          <strong>Login Not Permitted</strong>,
          <>
            <div style={{ margin: '8px 0' }}>
              User must be profiled first before proceeding.
            </div>
            <div>For assistance, please contact support.</div>
          </>,
        );
      } else {
        dispatch(setOAuthInfo(userInfo));
        sessionStorage.setItem('oauth_info', JSON.stringify(userInfo));
        history.push('/dashboard');
      }

      setIsLoading(false);
    } catch (error) {
      errorHandler();
    }
  };

  const getData = async () => {
    try {
      const client_data = await sessionStorage.getItem('client_ip');

      if (!client_data) {
        await dispatch(
          fetchClientData(), // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      errorHandler();
    }
  };

  useEffect(() => {
    // passing getData method to the lifecycle method
    getData();
  }, []);

  return (
    <div className="l-login">
      <div className="l-login__form-wrapper">
        <img src={Logo} alt="" className="l-login__form-logo" />
        <div className="l-login__form-title">Dashdot Application Store</div>
        <div className="l-login__form-msg">Login To Your Account</div>
        <GoogleLogin
          clientId={googleOAuthID}
          buttonText="Continue with Google"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={'single_host_origin'}
          render={(renderProps) => (
            // @ts-ignore
            <Button
              onClick={renderProps.onClick}
              className="l-login__google-button"
              loading={isLoading}
            >
              <GoogleLogo /> Continue with Google
            </Button>
          )}
        />
      </div>
    </div>
  );
};
export default Login;
