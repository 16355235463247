/**
 * This includes all the private routes which are child of dashboard/
 */

import AppAccess from '../../features/app_access/AppAccess';
import Dashboard from '../../features/dashboard/Dashboard';
import Sessions from '../../features/sessions/Sessions';

export type RouteType = {
  path: string;
  name: string;
  component?: () => JSX.Element;
  icon?: any;
  routes?: RouteType[];
  displayHeader?: boolean;
  exact?: boolean;
  strict?: boolean;
  isSubMenu?: boolean;
  headerTitle?: string;
  headerSubTitle?: string;
};

const routes: RouteType[] = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    exact: true,
    strict: true,
    displayHeader: false,
    // hideInMenu: true,
  },
  {
    path: '/sessions',
    name: 'Sessions',
    component: Sessions,
    exact: true,
    strict: true,
    displayHeader: false,
  },
  {
    path: '/app-access',
    name: 'App Access',
    component: AppAccess,
    exact: true,
    strict: true,
    displayHeader: false,
  },
];

export default routes;
