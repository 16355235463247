import { BackTop, Layout } from 'antd';
import React, { ReactNode, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import routes from '../../foundation/config/routes';
import useMedia from '../../foundation/utils/useMedia';
import ContentWrapper from './content_wrapper/ContentWrapper';
import Header from './header/Header';

type Props = {
  children: ReactNode;
};

const AppLayout = ({ children }: Props) => {
  const history = useHistory();
  const isDesktop = useMedia('min-width: 960px');

  const routeConfig = useMemo(() => {
    for (const route of routes) {
      if (route.path === history.location.pathname) {
        return route;
      } else if (route.routes) {
        for (const childRoute of route.routes) {
          if (childRoute.path === history.location.pathname) {
            return childRoute;
          }
        }
      }
    }
  }, [history.location.pathname]);
  return (
    <>
      <Layout className="c-app-layout">
        <Header routeConfig={routeConfig}>Header</Header>
        <Layout>
          <ContentWrapper routeConfig={routeConfig}>{children}</ContentWrapper>
        </Layout>
      </Layout>
      {!isDesktop && <BackTop />}
    </>
  );
};

export default AppLayout;
